/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Oval, ThreeDots } from "react-loader-spinner";
import { Accordion, AccordionItem } from "react-light-accordion";
import { RotatingLines } from "react-loader-spinner";
import Prism from "prismjs";

import { callOpenAi } from "../../services/openai-helper";
import Typewriter from "../typewriter";
import { UserMessage } from "../user-message";
import { SystemMessage } from "../system-message";
import { ErrorMessage } from "../error-message";

import {
  ChatIcon,
  CloseIcon,
  DownArrowIcon,
  ExpandIcon,
  SendIcon,
  SmythLogo,
} from "../../assets/svgs";

import "react-light-accordion/demo/css/index.css";
import "prismjs/themes/prism.css";
import "./styles.scss";
import { fetchSettings } from "../../services/chat-configurations";
import { AuthOverlay } from "../auth-overlay";

const _defaultMessage = {
  id: 1,
  type: "system",
  content: "",
  isTyped: false,
};

export const ChatBox = ({ domain = "", ...props }) => {
  const _inputRef = React.useRef(null);
  const [, setIsTyped] = useState(false);
  const [, setCurrentMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [debugInfo, setDebugInfo] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenStyle, setIsOpenStyle] = useState(false);
  const [executedIndices, setExecutedIndices] = useState([]);
  const [isFunctionCall, setIsFunctionCall] = useState(false);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const [canSendNewMessage, setCanSendNewMessage] = useState(true);
  const [defaultSettings, setDefaultSettings] = useState({ ...props });
  const [defaultMessage, setDefaultMessage] = useState(_defaultMessage);
  const [isDefaultSettingsLoading, setIsDefaultSettingsLoading] =
    useState(false);
  const [isAuthVerificationLoading, setIsAuthVerificationLoading] =
    useState(false);
  const [apiHeaders, setApiHeaders] = useState();

  const MemoizedUserMessage = React.memo(UserMessage);
  const MemoizedSystemMessage = React.memo(SystemMessage);

  const chatBoxRef = useRef(null);
  const chatEndRef = useRef(null);

  useEffect(() => {
    adjustHeight();
  }, [userInput, canSendNewMessage]);

  const adjustHeight = () => {
    const textarea = _inputRef.current;
    if (!textarea) return;
    const maxHeight = parseInt(getComputedStyle(textarea).lineHeight, 10) * 4;
    textarea.style.height = "auto"; // Resetting height to recalculate
    const updatedHeight = Math.min(textarea.scrollHeight, maxHeight);
    textarea.style.height = updatedHeight === 0 ? "28px" : `${updatedHeight}px`;
    textarea.style.overflowY =
      textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  useEffect(() => {
    Prism.highlightAll();
  }, [messages]);

  /**
   * Add event listener to detect if the window has regained focus
   * if yes call the fetchSettings function to get the latest settings
   */

  useEffect(() => {
    const handleFocus = async () => {
      if (!defaultSettings?.authRequired) return;
      try {
        setIsAuthVerificationLoading(true);
        const _settings = await fetchSettings(
          domain || defaultSettings?.domain
        );
        setDefaultSettings({
          domain,
          ..._settings,
          logo: _settings.icon || _settings.logo || props.logo,
          ...props,
        });
        setApiHeaders(_settings.headers);

        // Save X-Auth-Token to localStorage
        if (_settings.headers && _settings.headers['X-Auth-Token']) {
          localStorage.setItem('authToken', _settings.headers['X-Auth-Token']);
        }
      } catch (error) {
        console.log("error, ", error);
      } finally {
        setIsAuthVerificationLoading(false);
      }
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [defaultSettings?.authRequired]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        setIsDefaultSettingsLoading(true);

        // load default params/settings
        const _settings = await fetchSettings(
          domain || defaultSettings?.domain
        );
        setDefaultSettings({
          domain,
          ..._settings,
          logo: _settings.icon || _settings.logo || props.logo,
          ...props,
        });
        setApiHeaders(_settings.headers);
        setIsDefaultSettingsLoading(false);

        // if chatbotEnabled from _settings or props is false, we'll log it in console
        if (
          !_settings?.chatbotEnabled ||
          (Object.keys(props).find((p) => p === "chatbotEnabled") &&
            !props?.chatbotEnabled)
        ) {
          console.error(
            `Chatbot is disabled for this domain (${window.location.hostname})`
          );
        }

        setDefaultMessage({
          ...defaultMessage,
          content:
            defaultSettings?.introMessage ||
            `Hi, I am ${
              _settings?.name || "your AI assistant"
            }. How can I help you?`,
        });
      } catch (error) {
        setErrorMessage("Something went wrong! Please try again.");
      } finally {
        setIsLoading(false);
        setIsDefaultSettingsLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    debugInfo?.forEach((item, index) => {
      if (!executedIndices.includes(index)) {
        if (item.function) {
          executeDebugFunction(item);
          setExecutedIndices((prevIndices) => [...prevIndices, index]);
        }
      }
    });
  }, [debugInfo]);

  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight < scrollHeight) {
        if (!hasUserScrolled) {
          setHasUserScrolled(true);
        }
      }
    };

    if (chatBoxRef.current) {
      chatBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBoxRef.current) {
        chatBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatBoxRef.current]);

  const scrollToBottom = () => {
    return;
  };

  // Scroll to the bottom every time messages change
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const executeDebugFunction = (debugInfo) => {
    const { function: functionName, parameters } = debugInfo;
    if (typeof window[functionName] === "function") {
      window[functionName].apply(window, parameters);
    } else {
      console.warn(
        `The function ${functionName} does not exist on the window object.`
      );
    }
  };
  const inputChangeHandler = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow the newline
      } else {
        e.preventDefault();
        handleUserInputSubmit();
        scrollToBottom();
      }
    }
  };

  const renderInitialMessage = () => {
    return (
      <div className="chat-message system" key={defaultMessage.id}>
        <div
          className={`${"chat-content"} ${
            defaultSettings?.botBubbleClass || ""
          }`}
          style={{
            color: defaultSettings?.colors?.botBubbleColors?.textColor,
            background: `linear-gradient(to right, ${defaultSettings?.colors?.botBubbleColors?.backgroundColorStart}, ${defaultSettings?.colors?.botBubbleColors?.backgroundColorEnd})`,
          }}
        >
          {
            <div className="text">
              <Typewriter
                message={defaultMessage}
                speed={20}
                setCurrentMessage={(text) => setCurrentMessage(text)}
                setIsTyped={(message) => setIsTyped(message)}
              />
            </div>
          }
        </div>
      </div>
    );
  };

  const renderErrorMessage = () => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
    return (
      <ErrorMessage
        errorText={errorMessage}
        defaultMessage={defaultMessage}
        setErrorMessage={setErrorMessage}
        handleUserInputSubmit={handleUserInputSubmit}
        setCurrentMessage={setCurrentMessage}
        setIsTyped={setIsTyped}
      />
    );
  };

  const handleUserInputSubmit = async (shouldSetUserMessage = true) => {
    setCanSendNewMessage(false);
    // Clear the error message
    setErrorMessage("");
    const _aiInput = userInput;

    if (!userInput) return;
    _inputRef.current.value = "";

    // Create userMessage only if not regenerating or the flag is true
    const userMessage = shouldSetUserMessage
      ? {
          id: messages.length + 1,
          type: "user",
          content: userInput,
          updatedAt: new Date().getTime(),
        }
      : null;

    const systemMessage = {
      id: shouldSetUserMessage ? messages.length + 2 : messages.length,
      type: "system",
      content: "",
      isTyped: false,
      updatedAt: new Date().getTime(),
    };

    if (shouldSetUserMessage) {
      setMessages([...messages, userMessage, systemMessage]);
    }

    try {
      setIsLoading(true);
      // Response of ChatGpt
      const openAiResponse = await callOpenAi(
        _aiInput,
        defaultSettings?.domain,
        apiHeaders
      );

      setIsLoading(false);

      if (!openAiResponse || openAiResponse?.status !== 200) {
        setCanSendNewMessage(true);
        const { error } = await openAiResponse.json();
        setErrorMessage(error || "Something went wrong! Please try again.");
        return;
      }
      //#region #1 streaming response
      const reader = openAiResponse.body.getReader();
      let accumulatedData = "";

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();

        // Convert the chunk to text and accumulate
        accumulatedData += new TextDecoder().decode(value);

        if (!done) {
          // - data as it arrives
          const jsonObjects = splitDataToJSONObjects(accumulatedData);
          handleJSONObjects(jsonObjects, systemMessage);

          // Reset accumulatedData for next iteration
          accumulatedData = "";
        } else {
          setCanSendNewMessage(true);
          break;
        }
      }

      //#endregion
    } catch (error) {
      setCanSendNewMessage(true);
      setErrorMessage("Something went wrong! Please try again.");
      console.log(error);
    } finally {
      setIsLoading(false);
      // Select all anchor (link) elements in the document
      const links = document.querySelectorAll("a");
      const images = document.querySelectorAll("img");

      // Loop through each link and add the target="_blank" attribute
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
      });
      images.forEach((img) => {
        img.setAttribute("onerror", "this.style.display='none'");
      });
    }
  };

  const renderAllMessages = React.useCallback(() => {
    let skipLastMessage = false;

    // Check if the last message has both content and errorMessage
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.content && errorMessage) {
        skipLastMessage = true;
      }
    }

    return messages
      .sort((a, b) => a.updatedAt - b.updatedAt)
      .map((message, index) => {
        // Skip rendering the last message if the condition is met
        if (skipLastMessage && index === messages.length - 1) {
          return null;
        }

        if (message && message.type === "system") {
          return (
            <MemoizedSystemMessage
              key={index}
              message={message}
              debugInfo={debugInfo}
              errorMessage={errorMessage}
              botBubbleClass={defaultSettings?.botBubbleClass}
              colors={defaultSettings?.colors}
              syntaxHighlightTheme={defaultSettings?.syntaxHighlightTheme}
            />
          );
        } else if (message && message.type === "user") {
          return (
            <MemoizedUserMessage
              key={index}
              message={message}
              humanBubbleClass={defaultSettings?.humanBubbleClass}
              colors={defaultSettings?.colors}
            />
          );
        } else if (message && message.type === "debug") {
          return (
            <DebugAccordion
              key={message.id + message.title + index}
              debugInfo={message}
              title={message.title || "Debug"}
            />
          );
        }

        return null; // Handle other cases where message is not valid
      });
  }, [messages, errorMessage]);

  const renderedMessages = React.useMemo(() => {
    return renderAllMessages();
  }, [messages, errorMessage]);

  const chatboxToggler = () => {
    setIsOpen((isOpen) => !isOpen);
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const splitDataToJSONObjects = useCallback((data) => {
    const jsonStrings = data.split("}{").map((str, index, array) => {
      if (index !== 0) str = "{" + str;
      if (index !== array.length - 1) str += "}";
      return str;
    });

    // Convert JSON strings to objects
    return jsonStrings
      .map((str) => {
        try {
          return JSON.parse(str);
        } catch (error) {
          setErrorMessage("Something went wrong! Please try again.");
          console.error("Error parsing JSON:", error);
          return null;
        }
      })
      .filter(Boolean); // Removing any null values
  }, []);

  const handleJSONObjects = useCallback((jsonObjects, systemMessage) => {
    let newDebugInfo = [];

    for (const jsonObject of jsonObjects) {
      if (jsonObject.error) {
        setErrorMessage(jsonObject.error);
        return;
      }

      if (jsonObject.debug) {
        newDebugInfo.push({
          debug: jsonObject.debug,
          systemId: systemMessage.id,
          title: jsonObject.title || "",
          function: jsonObject.function,
          parameters: jsonObject.parameters,
          isInvoked: false,
          updatedAt: new Date().getTime(),
        });

        setMessages((prevMessages) => {
          // Check if an object with the same title exists
          const existingMessageIndex = prevMessages.findIndex(
            (message) => message.title && message.title === jsonObject.title
          );

          if (existingMessageIndex > -1) {
            // If found, create a copy of the array and modify the content of the found object
            const updatedMessages = [...prevMessages];
            updatedMessages[existingMessageIndex]?.content?.push(
              jsonObject.debug
            );
            return updatedMessages;
          } else {
            // If not found, add the new object to the array
            return [
              ...prevMessages,
              {
                id: prevMessages.length + 1,
                type: "debug",
                content: [jsonObject.debug],
                isTyped: false,
                systemId: systemMessage.id,
                title: jsonObject.title || "",
                function: jsonObject.function,
                parameters: jsonObject.parameters,
                isInvoked: false,
                updatedAt: new Date().getTime(),
              },
            ];
          }
        });

        setDebugInfo((prevDebugInfo) => [...prevDebugInfo, ...newDebugInfo]);
      }

      if (jsonObject.function_call && !isFunctionCall) {
        setIsFunctionCall(true);
      }

      if (jsonObject.content && jsonObject.content !== "") {
        if (!isFunctionCall) {
          setIsFunctionCall(false);
        }
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const systemMsgIndex = updatedMessages.findIndex(
            (msg) => msg.id === systemMessage.id
          );

          if (systemMsgIndex !== -1) {
            updatedMessages[systemMsgIndex].content += jsonObject.content;
            updatedMessages[systemMsgIndex].updatedAt = new Date().getTime();
          }

          return updatedMessages;
        });

        scrollToBottom();
      }
    }
  }, []);

  const onAuthSuccess = () => {
    setDefaultSettings({
      ...defaultSettings,
      authRequired: false,
    });
  };

  return (
    <div>
      {defaultSettings?.chatbotEnabled && !isDefaultSettingsLoading && (
        <button
          className="chatbot-toggler"
          style={{
            backgroundColor:
              defaultSettings?.colors?.chatTogglerColors?.backgroundColor,
            color: defaultSettings?.colors?.chatTogglerColors?.textColor,
          }}
          onClick={chatboxToggler}
        >
          {isOpen ? (
            <DownArrowIcon
              color={defaultSettings?.colors?.chatTogglerColors?.textColor}
            />
          ) : (
            <ChatIcon
              color={defaultSettings?.colors?.chatTogglerColors?.textColor}
            />
          )}
        </button>
      )}
      {isDefaultSettingsLoading && (
        <Oval
          height={50}
          width={50}
          color="#45c98d"
          wrapperStyle={{}}
          wrapperClass="chatbot-toggler toggler-spinner"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#45c98d"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      )}
      <div
        className={
          isOpenStyle
            ? `${"enlarge-box-wrapper"} ${"chat-box-wrapper"}`
            : "chat-box-wrapper"
        }
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="chatbox">
          <div
            className="chat-header"
            style={{
              backgroundColor:
                defaultSettings?.colors?.chatWindowColors
                  ?.headerBackgroundColor,
            }}
          >
            <div className="chat-brand">
              {defaultSettings?.logo ? (
                <img
                  src={defaultSettings?.logo}
                  alt=""
                  className="chatbox-logo"
                />
              ) : (
                <SmythLogo />
              )}
              <div className="btn-wrapper">
                <button
                  className="close-icon"
                  onClick={() => setIsOpenStyle(!isOpenStyle)}
                >
                  <ExpandIcon />
                </button>
                <button className="close-icon" onClick={() => setIsOpen(false)}>
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div
            ref={chatBoxRef}
            className="chat-body"
            style={{
              backgroundColor:
                defaultSettings?.colors?.chatWindowColors?.backgroundColor,
            }}
          >
            {defaultSettings.authRequired ? (
              <AuthOverlay
                name={defaultSettings?.name}
                method={defaultSettings.auth?.method}
                domain={domain || defaultSettings?.domain}
                onAuthSuccess={onAuthSuccess}
                isAuthVerificationLoading={isAuthVerificationLoading}
                authorizationUrl={defaultSettings?.auth?.authorizationUrl}
              />
            ) : (
              <>
                {defaultMessage?.content && renderInitialMessage()}
                {renderedMessages}
                <div ref={chatEndRef} />
                {isLoading && !isFunctionCall && (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color={
                      defaultSettings?.colors?.botBubbleColors
                        ?.backgroundColorStart || "#45c98d"
                    }
                    ariaLabel="three-dots-loading"
                    wrapperClassName=""
                    visible={true}
                  />
                )}
                {!isLoading && isFunctionCall && (
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "6px",
                      background: "#fff",
                      borderRadius: "6px",
                      padding: "8px",
                      border: "1px solid",
                      fontSize: "14px",
                    }}
                  >
                    <span id="plugin-call-message">
                      {defaultSettings?.pluginCallMessage
                        ? defaultSettings?.pluginCallMessage
                        : defaultSettings?.name
                        ? `Contacting ${defaultSettings?.name}`
                        : "Contacting Engine"}
                    </span>
                    <RotatingLines
                      strokeColor="black"
                      strokeWidth="3"
                      animationDuration="0.5"
                      width="20"
                      visible={true}
                    />
                  </div>
                )}
                {errorMessage && renderErrorMessage()}
              </>
            )}
          </div>
          <div
            className="chat-footer"
            style={{
              backgroundColor:
                defaultSettings?.colors?.chatWindowColors
                  ?.footerBackgroundColor,
            }}
          >
            <div className="chat-input">
              <textarea
                placeholder="Type a message..."
                autoFocus
                ref={_inputRef}
                onChange={(e) => setUserInput(e.target.value)}
                disabled={
                  !defaultMessage?.content ||
                  !canSendNewMessage ||
                  defaultSettings.authRequired
                }
                onKeyDown={(e) => inputChangeHandler(e)}
                rows={1}
                style={{ lineHeight: "24px" }}
              />
              <button
                disabled={!canSendNewMessage || defaultSettings.authRequired}
                className="chat-send"
                type="button"
                onClick={() => handleUserInputSubmit()}
                style={{
                  backgroundColor:
                    defaultSettings?.colors?.sendButtonColors?.backgroundColor,
                  color: defaultSettings?.colors?.sendButtonColors?.textColor,
                  cursor:
                    !canSendNewMessage || defaultSettings.authRequired
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChatBox.propTypes = {
  logo: PropTypes.string,
  pluginDomain: PropTypes.string,
  name: PropTypes.string,
  humanBubbleClass: PropTypes.string,
  botBubbleClass: PropTypes.string,
  introMessage: PropTypes.string,
  domain: PropTypes.string,
  chatbotEnabled: PropTypes.bool,
};

const DebugAccordion = ({ debugInfo, title }) => (
  <div className="debug-section">
    <Accordion atomic={true} key={debugInfo.systemId}>
      <AccordionItem title={title}>
        {debugInfo?.content?.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </AccordionItem>
    </Accordion>
  </div>
);

DebugAccordion.propTypes = {
  debugInfo: PropTypes.any,
};
